<template>
  <v-container>
    <v-card class="glass">
      <v-card-title>
        <v-icon left>
          mdi-handshake-outline
        </v-icon>
        Negocio
      </v-card-title>
      <v-divider/>
      <v-card-text>
           <v-row no-gutters>
      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin' || $store.state.Auth.token.claims.type == 'entrenador')">
        <v-card class="mx-auto  pt-5  fill-height glass" @click="showData('/qr')">


          <v-img class="white--text align-end">
            <v-icon color="primary" size="45">mdi-qrcode-scan</v-icon>
          </v-img>
          <v-card-text class="">
            Control de acceso
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="3" class="pa-2 text-center "
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin' || $store.state.Auth.token.claims.type == 'entrenador')">
        <v-card class="mx-auto pt-5 fill-height glass" @click="showData('/reservas')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="45">mdi-list-status</v-icon>
          </v-img>
          <v-card-text class="">
            Reservas
          </v-card-text>
        </v-card>
      </v-col>



      <!--  <v-col cols="6" class="pa-2 text-center"  v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type=='superuser' || $store.state.Auth.token.claims.type == 'admin')">
              <v-card class="mx-auto pt-5 fill-height glass" max-width="344"  @click="showData('/pagos')">
                <v-img class="white--text align-end">
                  <v-icon color="primary" size="50">mdi-cash

                  </v-icon>
                </v-img>
                <v-card-text class="">
                  Historial de pagos y membresias
                </v-card-text>
              </v-card>
          </v-col>  -->

      


      <!--   <v-col cols="6" md="3" class="pa-2 text-center"  v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser')">
            <v-card class="mx-auto pt-5 fill-height glass"  @click="showData('/checkout')">
              <v-img class="white--text align-end">
                <v-icon color="primary" size="45">
                  mdi-cash-register
                </v-icon>
              </v-img>
              <v-card-text>Checkout</v-card-text>
            </v-card>
      </v-col>  -->

     <!--  <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
        <v-card class="mx-auto pt-5 fill-height glass" @click="showData('/negocio/analitica')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="45">
              mdi-finance
            </v-icon>
          </v-img>
          <v-card-text>
            Analítica del centro
          </v-card-text>
        </v-card>
      </v-col> -->

      <!--  <v-col cols="6" md="3" class="pa-2 text-center"  v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type=='superuser' || $store.state.Auth.token.claims.type == 'admin')">
            <v-card class="mx-auto pt-5 fill-height glass"  @click="showData('/horarios')">
              <v-img class="white--text align-end">
                <v-icon color="primary" size="50">mdi-clock-edit-outline</v-icon>
              </v-img>
              <v-card-text>Horarios del centro <br>Dias especiales</v-card-text>
            </v-card>
        </v-col>  -->

      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
        <v-card class="mx-auto pt-5 fill-height glass" @click="showData('/negocio/ingresos-egresos')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-cash-sync
            </v-icon>
          </v-img>
          <v-card-text>Ingresos&Egresos</v-card-text>
        </v-card>
      </v-col>


      <!--  <v-col cols="6" md="3" class="pa-2 text-center"  v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin') ">
              <v-card class="mx-auto pt-5 fill-height glass"  @click="showData('/suscripciones')">
                <v-img class="white--text align-end">
                  <v-icon color="primary" size="50">
                    mdi-account-group
                  </v-icon>
                </v-img>
                <v-card-text class="">Suscripciones</v-card-text>
              </v-card>
          </v-col>  -->



      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')">
        <v-card class="mx-auto pt-5 fill-height glass" @click="showData('/negocio/ajustes')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-cog
            </v-icon>
          </v-img>
          <v-card-text class="">
            Ajustes
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser')">
        <v-card class="mx-auto pt-5 fill-height glass glass" @click="showData('/logs')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-file-document
            </v-icon>
          </v-img>
          <v-card-text class="">
            Logs
          </v-card-text>
        </v-card>
      </v-col>

       <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser')">
        <v-card class="mx-auto pt-5 fill-height glass glass" @click="showData('/recursos')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-file-document
            </v-icon>
          </v-img>
          <v-card-text class="">
            Recursos
          </v-card-text>
        </v-card>
      </v-col>


       <v-col cols="6" md="3" class="pa-2 text-center"
        v-if="$store.state.Auth.token.claims.type && ($store.state.Auth.token.claims.type == 'superuser')">
        <v-card class="mx-auto pt-5 fill-height glass glass" @click="showData('/encuestas')">
          <v-img class="white--text align-end">
            <v-icon color="primary" size="50">
              mdi-file-document
            </v-icon>
          </v-img>
          <v-card-text class="">
            Formularios
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
      </v-card-text>
    </v-card>




  </v-container>
</template>

<script>

export default {
  methods: {
    showData(route) {
      this.$vuetify.goTo(0);
      this.$router.push(route);
    }
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>
